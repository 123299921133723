@import "../variables";
@import "../utilities/unit";
@import "../components/common";

h1.gigya-screen-caption {
    text-align: center;
    padding-left: 0;
}

.gigya-screen-content {
    .gigya-screen.portrait {
        width: 100%;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .main-text,
    .gigya-composite-control-header {
        font-size: rem-calc(16);
        font-family: $font-antenna-purina;
        font-weight: 500;
    }

    .gigya-label-text {
        font-family: $font-antenna-purina;
    }
}

.card-gigya {
    width: 100%;
    max-width: rem-calc(600);
    margin: 0;
    padding: rem-calc(12 50 0 30);

    @include media-breakpoint-only(md) {
        padding: rem-calc(18 20 0); 
    }

    @include media-breakpoint-down(sm) {
        padding: rem-calc(12 20 0 0);
    }

    .gigya-screen {
        input[type="checkbox"],
        input[type="radio"] {
            &:focus {
                border: 0 !important;
                box-shadow: none !important;
            }
        }

        &.portrait {
            width: 100%;
            padding: 0;

            &:not(.gigya-update-profile-screen) {
                @include media-breakpoint-down(sm) {
                    padding-left: rem-calc(20);
                }

                @include media-breakpoint-up(lg) {
                    padding-left: rem-calc(20);
                }
            }

            &.mobile {
                padding-right: 0;

                @include media-breakpoint-down(md) {
                    padding-top: rem-calc(15);
                }
            }
        }

        .gigya-layout-cell {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .gigya-composite-control {
        @include media-breakpoint-down(md) {
            max-width: 90%;
            margin: 0;
        }

        .gigya-input-submit {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .gigya-composite-control-submit,
    .gigya-composite-control-link {
        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }
    }

    .gigya-composite-control-header {
        font-size: rem-calc(25);
        font-family: $font-antenna-purina;
        font-weight: 500;
        color: $color-black;

        @include media-breakpoint-down(md) {
            font-size: rem-calc(25) !important;
            color: $color-black;
        }
    }    
}

#register-screen {
    width: 100%;
    max-width: rem-calc(600);
    margin: 0 auto;
    padding: rem-calc(15);

    .gigya-screen.portrait {
        @include media-breakpoint-only(md) {
            padding: rem-calc(30);
        }
    }

    .gigya-label {
        flex: 0 auto;
    }
}

#update-pet-info-screen,
#update-email-preferences-screen,
#update-vet-clinic-screen {
    margin: rem-calc(0 0 16);
}

#update-pet-info-screen_content,
#update-vet-clinic-screen_content {
    .gigya-screen {
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
}
