$button-hover: #2b2b2b;
$btn-primary:#2b2b2b;

.btn {
    padding: rem-calc(12 24);
    transition: none !important;

    &:hover {
        transform: none !important;
    }

    &.c-button--primary {
        background-color: $color-black;
        color: $white;
        border: 1px solid $btn-primary;
        border-radius: rem-calc(50);

        &:hover {
            background-color: $white;
            color: $color-black;
        }
    }

    &.btn-outline {
        border: 1px solid $color-black;
        color: $color-black;
        background-color: transparent;
        font-weight: 400;
        border-radius: rem-calc(50);
        font-size: rem-calc(16);
    }


    &.load-more-button {
        display: block;
        margin: 36px auto;
        border: 1px solid $black;
        border-radius: 24px;
        background: $black;
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
        cursor: pointer;

        &:hover,
        &:active {
            background: $white;
            cursor: pointer;
            color: $black;
        }
    }
}

button {
    .fa-check-circle::before,
    .fa-square-o::before,
    .fa-check-square::before {
        border: 1px solid $black;
        border-color: $color-black;
    }

    &:hover {
        .fa-circle-o::before,
        .fa-square-o::before {
            border-color: $black;
        }
    }
}
