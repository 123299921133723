@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./contentWithImage__typography";

.contentWithImage-title {
    margin-bottom: 16px;
}

.contentWithImage-content-text {
    overflow: hidden;
    margin-top: 24px;
    min-height: 85px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        width: 90%;
    }

    p {
        margin-bottom: 0;

        /* stylelint-disable-next-line */
        &:not(:first-of-type) {
            /* stylelint-disable-next-line */
            br {
                margin-top: 45px;
            }
        }
    }

    .read-more & {
        max-height: none;
    }
}

.contentWithImage-image-container {
    width: 100%;

    .read-more & {
        max-height: none;
    }
}

.contentWithImage-content-wrapper {
    padding: 32px 24px;
    margin: 0;
    text-align: center;
}

.contentWithImage-buttons {
    margin-top: 24px;

    .read-more & {
        display: none;
    }

    .btn {
        border: 0;
        border-radius: 100px;
        overflow: hidden;
        padding: 16px 32px;
        text-overflow: ellipsis;
        transition: transform 0.4s;
        white-space: nowrap;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }

        /* stylelint-disable-next-line */
        &:hover {
            transform: scale(1.03);
        }

        /* stylelint-disable-next-line */
        &:focus {
            text-decoration: none;
        }
    }
}

.contentWithImage-image {
    width: 100%;
}

.experience-assets-contentWithImage {
    width: 100%;
    padding: 16px 16px 0;

    @include media-breakpoint-up(lg) {
        width: 49%;
        display: inline-block;
        padding:0;

        &:first-child {
            padding-right: 8px;
        }

        &:nth-child(2) {
            padding-left: 8px;
        }
    }

    .layout-full-width {
        @include media-breakpoint-up(lg) {
            padding: 0 16px 0 0;
        }
    }
}
