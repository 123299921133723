@import "/cartridges/app_purina_core/cartridge/client/default/scss/global.scss";

@import "components/common-styles";
@import "components/buttons";
@import "components/search-filter";
@import "experience/components/assets/contentWithImage";

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: $white !important;

    &:hover {
        color: $black !important;
    }
}

