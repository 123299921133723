@import "~base/components/header";

.c-header {
    background-color: $black;
    height: fit-content;
    padding: rem-calc(26 16 18);
    border-bottom: rem-calc(1) solid $primary;

    @include media-breakpoint-up(lg) {
        height: rem-calc(143);
        padding: rem-calc(30 16 44);
    }

    &__create-account {
        @include primary-button;
        margin-right: rem-calc(8);
    }

    &__login-button {
        @include secondary-button;
    }

    &__left {
        @include media-breakpoint-down(lg) {
            padding-bottom: rem-calc(20);
        }
    }

    &__right {
        text-align: left;
    }

    &__account {
        padding: rem-calc(30 0 0 20);

        @include media-breakpoint-down(md) {
            padding: rem-calc(20 0 0 20);
        }
    }

    &__mini-cart {
        padding: rem-calc(28 0 0 20);

        @include media-breakpoint-down(md) {
            padding: rem-calc(20 0 0 20);
        }
    }

    &__mobile {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &__desktop {
        display: none;

        @include media-breakpoint-up(lg) {
            display: contents;
        }
    }

    &__back-to-cart,
    &__secure-checkout {
        font-size: rem-calc(12);
        margin-top: rem-calc(4);
    }

    &__back-to-cart-icon,
    &__secure-checkout-icon {
        margin-right: rem-calc(10);
    }

    &__back-to-cart-link {
        color: $primary;

        &:hover {
            color: $secondary;
            text-decoration: none;
        }
    }

    > .row {
        align-items: center;
    }
}

.main-menu {
    background-color: var(--skin-banner-background-color-1);

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: $color-black;
        border: 0;
        -webkit-appearance: none;
        color: $primary;
        font-size: rem-calc(14);
        text-transform: uppercase;

        @include media-breakpoint-up($next-breakpoint) {
            display: block;
        }
    }
}

.navbar-header {

    .user {
        margin: 0;
        line-height: normal;

        .popover {
            background-color: $black;
            font-size: rem-calc(14);
            padding: rem-calc(10 0);
            border: none;
            border-radius: 0;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            left: rem-calc(-70);
            top: 25px;

            a {
                white-space: nowrap;
                margin-bottom: 0;
                color: $primary;
                display: block;
                padding: rem-calc(10 70 10 10);

                &:hover,
                &:focus {
                    background-color: $menu-bg;
                    text-decoration: none;
                }
            }

            .nav-item {
                width: 100%;
            }
        }
    }
}


.navbar-toggler {
    border: none;
    color: $primary;
    font-size: rem-calc(30);
    padding: rem-calc(0 10 0 0);
}

.hide-link-med {

    @include media-breakpoint-only(md) {
        display: block;
    }
}

.c-header__actions {
    position: relative;
    display: inline-block;
    height: auto;
}

.c-header-nav {
    background-color: $black;
}


.c-header__create-account-links {
    background-color: $black;
    font-size: rem-calc(16);
    padding: rem-calc(10 0);
    border: none;
    border-radius: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 2;
    position: absolute;
    width: rem-calc(176);
    top: rem-calc(49);
    text-align: center;
    letter-spacing: rem-calc(1);
}

.c-header__login-links-VD {
    background-color: $black;
    font-size: rem-calc(16);
    padding: rem-calc(10 0);
    border: none;
    border-radius: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 2;
    position: absolute;
    width: rem-calc(128);
    top: rem-calc(49);
    text-align: center;
    letter-spacing: rem-calc(1);
    right: -1px;
}

.c-header__create-account-links a {
    white-space: nowrap;
    margin-bottom: 0;
    color: $primary;
    display: block;
    padding: rem-calc(10 0);
    letter-spacing: rem-calc(1);

    &:hover,
    &:focus {
        background-color: $menu-bg;
        text-decoration: none;
    }
}

.c-header__login-links-VD a {
    white-space: nowrap;
    margin-bottom: 0;
    color: $primary;
    display: block;
    padding: rem-calc(10 0);
    letter-spacing: rem-calc(1);

    &:hover,
    &:focus {
        background-color: $menu-bg;
        text-decoration: none;
    }
}

.c-header--no-menu {
    padding: rem-calc(20 32);
    background-color: $black;
    color: $primary;

    @include media-breakpoint-down(md) {
        padding: rem-calc(20 16);
    }
}

.c-header__login-button {
    @include outline-button;
}
