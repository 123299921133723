@import "/cartridges/app_purina_core/cartridge/client/default/scss/components/_common.scss";
@import "/cartridges/app_purina_core/cartridge/client/default/scss/components/_menu.scss";
@import "/cartridges/app_purina_core/cartridge/client/default/scss/components/_footer.scss";

$grey-border: #2b2b2b;
$text-color: #bcbcbc;
$brand-color: #e81c24;
$nav-color: #1e1e1e;

$primary-purina-dark-grey: #2b2b2b;
$neutrals:#959595;
$button-hover: #2b2b2b;
$btn-primary: $grey-border;
$btn-primary-hover: #2b2b2b;

body {
    background-color: $white;
}

.c-search {
    padding-top: 0;

    @include media-breakpoint-only(lg) {
        width: 100%;
        padding-left: rem-calc(15);
        margin-bottom: rem-calc(15);
    }

    &.search {
        padding-top: 0;
    }

    input,
    input:focus {
        background-color: $grey1;
    }

    input[placeholder] {
        color: $brand-black;
    }

    .site-search > form {
        margin-bottom: 0;
    }

    .suggestions-wrapper {
        @include media-breakpoint-only(lg) {
            .suggestions {
                max-width: 100%;
            }

            #search-results {
                max-width: 100%;

                > li,
                .justify-content-end {
                    justify-content: flex-start !important;

                    .offset-sm-2,
                    a {
                        margin-left: rem-calc(5);
                    }
                }
            }
        }
    }
}

.navigation {
    padding: 0;
    background-color: $white;
}

.main-menu .navbar.bg-inverse {
    padding: 0;

    @include media-breakpoint-only(lg) {
        justify-content: center;
    }

    .navbar-nav {
        .nav-link {
            color: $nav-color;
            font-weight: 700;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                color: $nav-color !important;
                padding: rem-calc(36 20) !important;
                font-weight: 700;
            }
        }

        .nav-item:not(:last-child, .dropdown) {
            @include media-breakpoint-down(md) {
                border-bottom: 1px solid $primary-purina-dark-grey;
            }
        }

        .dropdown-menu {
            @include media-breakpoint-up(lg) {
                margin: rem-calc(38 0 0);

                .dropdown-menu {
                    margin: rem-calc(10 0 0);
                }
            }
        }
    }

    .dropdown-menu {
        .dropdown-item {
            padding: rem-calc(12 16);

            @include media-breakpoint-up(lg) {
                padding: rem-calc(12 32 12 16);
                border-bottom: 1px solid #f3f3f3;
            }
        }

        .dropdown-link {
            padding: 0;
        }
    }
}

.header {
    .navbar-toggler {
        color: $black;
    }
}

.main-menu.mobile.menu-toggleable-left {
    background-color: $white;

    @include media-breakpoint-down(lg) {
        max-height: 100vh;
    }

    @include media-breakpoint-down(md) {
        background-color: $white;
    }

    @include media-breakpoint-down(md) {
        .bg-inverse,
        &.navbar-toggleable-md {
            background-color: $white !important;
            color: $nav-color;
        }

        .nav-link {
            color: red;
        }
    }

    .close-menu {
        background-color: $white;
        border-bottom: 1px solid #f3f3f3;
        color: $nav-color;

        button {
            color: $nav-color;
            background-color: $white;
            align-items: center;
            display: flex;
        }

        .back {
            margin: rem-calc(2) 0 0;

            span {
                display: flex;
            }
        }
    }

    &.in {
        @include media-breakpoint-down(md) {
            min-width: 80% !important;

            .nav-item + .nav-item,
            .dropdown-item,
            .dropdown {
                border-bottom: 1px solid #f3f3f3;
                border-top: none;

                .top-category {
                    .dropdown-link {
                        padding: rem-calc(12 15 12 0);
                    }
                }
            }
        }

        .dropdown-menu,
        .dropdown-item {
            background-color: $white;
            color: $nav-color;
            box-shadow: none;

            &:not(.top-category) {
                font-weight: 400;
            }

            .dropdown-link {
                color: $nav-color;
                text-transform: none;
            }

            &.top-category > .nav-link {
                font-weight: 700;
                text-transform: none;
                padding-left: 0;
            }
        }
    }
}

.main-menu.mobile .navbar .close-menu button,
.main-menu.mobile .navbar .close-button button {
    background-color: $white;
    color: $black;

    @include media-breakpoint-down(md) {
        display: flex;
    }
}

.c-header-nav {
    background-color: $white;
    padding: 0;
    border-bottom: rem-calc(1) solid $card-border;

    .c-header {
        padding: rem-calc(16);
        border-bottom: none;
        background-color: $white;
        height: auto;

        @include media-breakpoint-up(lg) {
            padding: rem-calc(0 32);
            height: auto;
        }

        &__right {
            @include media-breakpoint-up(lg) {
                padding: rem-calc(20 0);
            }
        }

        .d-flex {
            align-items: center;
        }
    }
}

.campaign-banner {
    background-color: $brand-color !important;
}

.multilevel-dropdown {
    .dropdown-menu {
        > .dropdown {
            > .dropdown-menu {
                @include media-breakpoint-down(lg) {
                    top: 0;
                }

                @include media-breakpoint-up(md) {
                    left: -100%;
                }
            }

            &.show {
                > .dropdown-menu {
                    @include media-breakpoint-up(md) {
                        left: 0;
                    }
                }
            }
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        margin-top: rem-calc(5);
    }
}

.main-menu.multilevel-dropdown {
    .dropdown-menu {
        top: 0 !important;
        background-color: $white;
        color: $nav-color;
        font-size: rem-calc(16);
        padding: 0;

        @include media-breakpoint-down(md) {
            height: 100vh !important;
        }

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
            top: 58px !important;
        }

        .close-menu {
            margin-top: 0;;
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-toggle::after {
        display: none;

        @include media-breakpoint-down(md) {
            content: "";
            display: inline-block;
            background-image: url('../images/arrow-back.svg');
            width: rem-calc(30);
            height: rem-calc(12);
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
            border-left: 0 !important;
        }
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em !important;
            left: 100%;
            padding: 0;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
            padding: rem-calc(12 16 12 32);
            font-size: rem-calc(16);
            line-height: normal;

        .dropdown-link {
            display: block;
            padding: 0.425em 5em 0.425em 0;
            color: $nav-color;

            &:hover {
                text-decoration: none;
            }
        }

        + .dropdown-item {
            border-top: none;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;

                @include media-breakpoint-down($breakpoint-name) {
                    padding-left: rem-calc(25);
                }
            }
        }

        &:hover,
        &:focus {
            background-color: #f3f3f3;
            color: $nav-color;
            text-decoration: none;
        }
    }
}

.c-footer {
    padding: rem-calc(32 5);
    margin-top: 32px;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(64 32 0 32);
        margin-top: 64px;
    }

    &__hr {
        border-color: $primary-purina-dark-grey;
    }

    &__title {
        color: $white !important;
        font-weight: 700;
        text-transform: capitalize;
    }

    &__links {
        color: $text-color !important;;

        &:hover {
            color: $brand-color !important;;
        }
    }
}

.campaign-banner {
    .campaign-banner-message {
        color: $white;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    animation: fade-in 0.5s ease-in-out;
}


.swiper-button-next, .swiper-button-prev {
    background-color: $white;
}


.banner-content-title {
    font-weight: 700;
    width: auto;
    line-height: initial;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
        line-height: normal;
        width: 100%;
    }
}

.experience-main,
.article-buttons {
    .btn,
    .button-link-btn,
    .up-2-cta-content-button,
    .image-button-content-btn {
        border: 1px solid  $black;
        border-radius: 99px;
        overflow: hidden;
        padding: 16px 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
        background-color: $black;
        color: #fff;
        font-family: var(--font-primary);
        font-size: var(--text-sm);
        font-weight: 400;
        height: auto;
        min-height: auto;
        box-shadow: none;

        @include media-breakpoint-up(lg) {
            width: auto;
            font-size: var(--text-sm);
        }

        /* stylelint-disable-next-line */
        &:hover {
            box-shadow: none;
            color: $black;
            text-decoration: none;
            background-color: $white;
        }

        /* stylelint-disable-next-line */
        &:focus {
            text-decoration: none;
        }
    }

    .up-2-cta-content-button{
        width: fit-content;

        span {
            line-height: normal;
        }
    }

    .image-button-content-btn {
        width: fit-content;
        padding: 18px 33px;
    }

    .up-2-cta-content-title {
        margin-bottom: 24px;
    }
}

.experience-component  {
    .up-1-item-carousel {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }
    }

    &.experience-layouts-up1ItemCarousel,
    &.experience-assets-banner {
        &:first-child {
            @include media-breakpoint-down(lg) {
                border-bottom: 1px solid #F3F3F3;
            }
        }
    }

    .article-content-title {
        color: var(--primary-purina-dark-grey);
    }

    .experience-buttonLinks {
        line-height: normal;
    }
}

.banner-content {
    .c-button--primary.btn-color-filled {
        padding: 16px 32px;
        border-radius: 99px;
        font-size: 16px;
        font-weight: 400;

        @include media-breakpoint-down(md) {
            color: $white !important;
            background-color: $primary-purina-dark-grey !important;
        }
    }
}

.page {
    background-color: $white !important;

    #homepage-Purina_AU {
        .experience-assets-banner {
            &:first-child {
                @include media-breakpoint-down(lg) {
                    background-color: #F3F3F3;
                }
            }

            .banner-content {
                @include media-breakpoint-up(lg) {
                    padding-left: rem-calc(120);
                }
            }
        }
    }
}

.btn.btn-outline {
    border: 1px solid $color-black;
    color: $color-black;
    background-color: transparent;
    font-weight: 400;
    padding: 12px 24px;
    border-radius: 50px;
    font-size: 16px;
}

.c-pdp-wrapper {
    .brand-name {
        color: $brand-color !important;
    }

    .item-code {
        color: $neutrals !important;
    }
}

.c-button--primary,
.button-back-to-homepage {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
    font-size: rem-calc(16);
    padding: rem-calc(12 16);
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: rem-calc(1);
    border-radius: 50px;

    &:disabled,
    [readonly] {
        background-color: $text-color;
        color: $white;
        opacity: 1;
    }

    &:hover {
        text-decoration: none;
    }

    &.btn-color-filled {
        color: $black;
        margin-right: rem-calc(20);
    }
}


.content-404 {
    &-search {
        border: 1px solid $text-color;
    }

    &-title,
    &-sub-title,
    &-description {
        color: #1E1E1E;
    }

    &-search-field {
        /* stylelint-disable-next-line */
        &,
        &[placeholder] {
            color: #959595;
        }
    }

    .c-button--primary {
        border: 1px solid $black;
        border-radius: 99px;
        color: $white;
        background-color: $black;

        &:hover {
            text-decoration: none;
            color: $black;
            background-color: $white;
        }
    }
}

.recommendation-slider {
    .swiper-button-next,
    .swiper-button-prev {
        color: $black;
        background-color: $white;
    }
}

.search-no-results {
    .button-back-to-homepage {
        border: 1px solid $black;
        padding: rem-calc(16 32);
        line-height: rem-calc(20);
        font-weight: 400;
        border-radius: rem-calc(50);
        background-color: $black;
        color: $white;

        a {
            font-weight: 400;
            text-decoration: none;
            text-transform: none;
        }

        &:hover {
            background-color: $white;

            a {
                color: $black;
            }
        }
    }
}

.content-404 {
    .content-404-button {
        background-color: $black;
        text-align: center;
        border-top: 1px solid $black;
        color: $white;
        display: inline-block;
        transition: none;

        &:hover {
            background-color: $white !important;
            color: $black;
            transform: none;
        }
    }

    .content-404-search {
        border-color: $text-color;
        border-radius: 4px;
    }

    .content-404-search-submit,
    .content-404-search-field {
        border-radius: 4px;
    }
}


.product-tile-wrapper {
    margin-bottom: 20px;

    .breadcrumb-icon {
        margin: -3px 0 0 0;
    }

    .btn {
        &:hover {
            color: $black !important;
        }
    }
}

.product-tile,
.product-tile-pd {
    margin-bottom: 0 !important;

    .tile-body {
        padding: 0.625em 0 0 !important;

        @include media-breakpoint-down(lg) {
            min-height: 160px !important;
        }
    }

    .product-name-link {
        padding: 0.625em 0 0 !important;

        @include media-breakpoint-down(lg) {
            min-height: 160px !important;
        }
    }

    .brand-name,
    .product-price,
    .size-swatches {
        display: none;
    }
}

.btn.adimo-multi-touchpoint {
    padding: 10.5px 32px;
}

.startup-kit-page {
    .btn.adimo-multi-touchpoint {
        padding: 10.5px 32px 10px;
    }
}


.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-next {
    background-color: #2b2b2b !important;
    height: 32px !important;
    width: 32px !important;
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-prev {

    .carousel-control-prev-icon {
        left: 20% !important;
        content: url("../images/chevron-right-white.svg") !important;
    }
}

.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-next {

    .carousel-control-next-icon {
        right: 20% !important;
        content: url("../images/chevron-right-white.svg") !important;
    }
}

.gigya-screen-content .gigya-screen.portrait,
.gigya-screen.portrait.mobile {
        padding: 0 !important;
}

.gigya-screen.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-code-remember-checkbox,
.gigya-screen input[type=checkbox], .gigya-screen input[type=radio] {
    margin: -3px 0 0 0 !important;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-label,
.gigya-screen .gigya-composite-control.gigya-composite-control-link {
    font-size: 20px !important;
    padding: 10px 0 !important;
}

.gigya-screen.portrait.mobile .gigya-composite-control-label {
    font-size: 16px !important;
    padding: 10px 0 !important;
}

.gigya-screen-content {
    padding-top: 0;
}

#register-screen {
    max-width: 50% !important;

    @include media-breakpoint-down(lg) {
        max-width: 100% !important;
    }
}

.gigya-screen .gigya-label-text {
    font-size: 16px !important;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-multi-choice .gigya-multi-choice-item label {
}

.gigya-screen h1, .gigya-screen h2 {
    font-weight: 700 !important;
}

#newsletter-heading {
    padding-top: 32px !important;
    text-align: center;

    @include media-breakpoint-down(lg) {
        padding-top: 20px !important;
    }

    h1 {
        font-size: 64px !important;
        font-weight: 700 !important;

        @include media-breakpoint-down(lg) {
            font-size: 40px !important;
        }
    }
}

.gigya-screen .gigya-composite-control.gigya-composite-control-dropdown .gigya-error-msg-active,
.gigya-screen .gigya-composite-control.gigya-composite-control-fieldset .gigya-error-msg-active,
.gigya-screen .gigya-composite-control.gigya-composite-control-password .gigya-error-msg-active,
.gigya-screen .gigya-composite-control.gigya-composite-control-textarea .gigya-error-msg-active,
.gigya-screen .gigya-composite-control.gigya-composite-control-textbox .gigya-error-msg-active,
.gigya-screen.portrait .gigya-error-msg-active.gigya-form-error-msg,
.gigya-screen.portrait .gigya-error-msg.gigya-error-msg-active {
    text-align: left;
    font-size: 12px !important;
    padding: 0 0 10px !important;
    line-height: 5px !important;
}

.swiper-slide {
    .btn {
        &.c-button--primary {
            color: $white;
            background-color: $black;

            &:hover {
                color: $black;
                background-color: $white;
            }
        }
    }
}

.add-to-cart {
    .breadcrumb-icon {
        margin: -3px 0 0 0;

        &::before {
            content: "";
            background-image: url(../../images/shopping-cart-white.svg);
            width: 20px;
            height: 20px;
            background-size: 100%;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: text-bottom;
            position: relative;
        }
    }

    &.btn {
        &:hover {
            border-color: $btn-primary-hover;

            .breadcrumb-icon::before {
                width: 20px;
                height: 20px;
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: text-bottom;
                position: relative;
                background-image: url('../../images/shopping-cart-black.svg');
            }
        }
    }
}

.button-link {
    .btn-primary-link,
    .btn-secondary-link {
        border: 1px solid  $black;
        color: $white;
        background-color: $black;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }
}

.page-breadcrumbs {
    .breadcrumb {
        font-size: 14px;
        padding: 16px 16px 0;
    }
}

.articleContent-buttons,
.contentWithImage-buttons,
.banner-buttons {
    .btn,
    .banner-btn {
        border: 1px solid $black;
        background-color: $black;
        color: $white;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }

    .btn-primary-state {
        border: 1px solid $black;
        background-color: $black;
        color: $white;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }
}

.c-button--primary.button-state--primary,
.c-button--primary {
    border: 1px solid $black;
    background-color: $black;
    color: $white;

    &:hover {
        color: $black;
        background-color: $white;
    }
}
