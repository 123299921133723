@import "~base/components/menu";

$breakpoint-name: "md";
$breakpoint-name: "md";
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;
            background-color: $black;

            &.in {
                min-width: 50%;
                left: 0;
                right: unset;
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: rem-calc(22 0);

    @include media-breakpoint-up(lg) {
        padding: 0;
    }


    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: 0.8rem;
            white-space: nowrap;
            color: $primary;
            text-transform: uppercase;
            font-size: rem-calc(16);
            font-weight: 500;
        }
    }

    @include media-breakpoint-down($next-breakpoint) {
        padding: 0;
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: transparent;
    color: $primary;

    @include media-breakpoint-down($breakpoint-name) {
        color: $primary;
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $black;
    }
}

.menu-toggleable-left {
    background-color: $black;

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $black;
        z-index: 4;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: $black !important;
            color: $primary;
            padding: 0;
        }
    }

    .close-menu {
        padding: rem-calc(15 4);
        background-color: $black;
        border-bottom: 1px solid $secondary;
        flex: 0 0 100%;
        display: block;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em;
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $secondary;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                color: $primary;
                font-size: rem-calc(16);
                padding: rem-calc(12 15 12 25);
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
                background-color: $black;
                color: $primary;
            }

            .dropdown-item {
                background-color: $black;
                color: $primary;
                border-bottom: rem-calc(1) solid $secondary;
                font-size: rem-calc(16);
                padding: rem-calc(12 15 12 25);
                text-transform: uppercase;
                white-space: normal;

                &.top-category {
                    padding: 0;
                    font-weight: 700;
                }
            }

            .dropdown-link {
                color: $primary;
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 90%;
        background-color: $black;
        color: $primary;
        font-size: rem-calc(14);

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        padding: 0 0 0 1em;

        .dropdown-link {
            display: block;
            padding: 0.425em 5em 0.425em 0;
            color: $primary;
        }

        + .dropdown-item {
            border-top: none;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
                text-transform: uppercase;
                @include media-breakpoint-down($breakpoint-name) {
                    padding-left: rem-calc(25);
                }
            }
        }

        &:hover,
        &:focus {
            background-color: $menu-bg;
            color: $secondary;
            text-decoration: none;
        }
    }
}

.button-back-to-homepage {
    background: $primary;
    padding: rem-calc(9 21 9 21);
}

.search-no-product {
    text-align: center;
}

.button-homepage {
    text-align: center;
}

.search-result-texts {
    text-align: left;
}

.non-pd {
    .minicart {
        .minicart-quantity {
            top: -10px;
            left: -8px;
        }
    }
}
