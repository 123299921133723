@import "../variables";

// Buttons
// ----------------------------------------
.btn {
    border-radius: $button-border-radius;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    padding: rem-calc(12 16);
    min-height: rem-calc(40);

    &:focus,
    &:active {
        box-shadow: none;
    }
}

@mixin btn-md {
    padding: rem-calc(12 16);
}

@mixin btn-lg {
    padding: rem-calc(12 32);
}

@mixin primary-button() {
    background-color: $primary;
    font-size: rem-calc(14);
    text-transform: uppercase;
    padding: rem-calc(13);
    font-weight: 500;
    border: 0;
    color: $color-black;
    box-shadow: none;

    &:hover,
    &:focus,
    &:focus:active,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:not(:disabled) {
        background-color: $button-hover;
        color: $color-black;
        box-shadow: none;
    }
}

@mixin secondary-button() {
    border-radius: $button-border-radius;
    color: $primary;
    background-color: transparent;
    font-size: rem-calc(14);
    text-transform: uppercase;
    border: 2px solid $primary;
    padding: rem-calc(12 19);
}

@mixin outline-button {
    color: $button-hover;
    border-color: $button-hover;

    &:hover,
    &:hover,
    &:focus,
    &:focus:active,
    &:not(:disabled):not(.disabled):active,
    &:active,
    &:active:not(:disabled) {
        color: $button-hover;
        background-color: transparent;
        border-color: $button-hover;
        box-shadow: none;
    }
}

button {
    &.btn-primary,
    &.btn-primary:focus {
        @include primary-button();
    }

    .fa {
        position: relative;
    }

    .fa-check-circle::before,
    .fa-square-o::before,
    .fa-check-square::before {
        width: rem-calc(20);
        height: rem-calc(20);
        content: "";
        background-color: $color-white;
        border: 1px solid $button-hover;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: rem-calc(3);
    }

    .fa-check-circle::after {
        width: rem-calc(12);
        height: rem-calc(12);
        background-color: $button-hover;
        border-radius: 50%;
        content: "";
        position: absolute;
        top: rem-calc(7);
        left: rem-calc(4);   
    }

    .fa-circle-o::before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $color-white;
        border: 1px solid $color-black;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: rem-calc(3);
    }

    &:hover {
        .fa-circle-o::before,
        .fa-square-o::before {
            border-color: $button-hover;
        }
    }

    .fa-square-o::before,
    .fa-check-square::before {
        border-radius: rem-calc(4);
        border-color: $color-black;
    }

    .fa-check-square::before {
        border-color: $button-hover;
    }

    .fa-check-square::after {
        background-image: url("../images/checked.svg");
        background-repeat: no-repeat;
        width: rem-calc(12);
        height: rem-calc(12);
        content: "";
        position: absolute;
        top: rem-calc(8);
        left: rem-calc(4);
    }
}

.btn {
    &.btn-outline-primary {
        @include outline-button;
    }
}
