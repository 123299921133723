$swatch-border-active: #e81c24;

.search-results {
    .results-count {
        @include media-breakpoint-down(md) {
            padding: 0;
            margin: rem-calc(5) 0 rem-calc(30);
        }
    }

    .filter-results {
        .m-icon-filter::before {
            @include media-breakpoint-down(md) {
                background-image: url('../images/filter-mobile-white.svg');
            }
        }
    }

    .reset.btn {
        &:hover {
            background-color: $white;
            border-color: $btn-primary-hover;
            color: $btn-primary-hover;

            .m-icon-reset::before {
                background-image: url('../images/reset.svg');
            }
        }

        .m-icon-reset::before {
            background-image: url('../images/reset-white.svg');
        }
    }
}

.search-results {

    .search-nav {
        border-bottom: 1px solid $swatch-border-active !important;
    }

    .nav-link {
        color: $color-black !important;

        &.active,
        &:hover,
        &:focus {
            border-color: $swatch-border-active !important;
        }
    }
}

.content-grid-header {
    .card {
        margin-bottom: rem-calc(20);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(29);
        }
    }
    .card-header {
        max-width: rem-calc(297);
        max-height: rem-calc(169);
        border-bottom: 0;

        @include media-breakpoint-down(md) {
            max-width: rem-calc(343);
            max-height: rem-calc(194);
        }

        .card-img-top {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .card-body {
        padding: rem-calc(20 10);

        @include media-breakpoint-up(md) {
            padding: rem-calc(32);
        }

        .card-title {
            border-bottom: solid rem-calc(1) #d9d9d9;
            font-weight: 700;
            line-height: rem-calc(24);
            padding-bottom: rem-calc(19);

            @include media-breakpoint-down(md) {
                font-size: rem-calc(20);
                padding-bottom: rem-calc(16);
            }

            a {
                color: #2b2b2b;
            }
        }

        .card-content {
            font-weight: 400;
            line-height: rem-calc(19);
            padding-top: rem-calc(19);

            @include media-breakpoint-down(md) {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
        }
    }
}
