@import "~base/components/productTiles";

.search-results {
    padding: rem-calc(16);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(32);
    }

    @include media-breakpoint-down(lg) {
        padding: rem-calc(18);
    }
}

.search-nav {
    border: none;
    margin: 0;
}

.product-tile,
.product-tile-pd {
    margin-bottom: rem-calc(32);

    .image-container {
        box-shadow: none;

        a::after {
            content: none;
        }

        .overlay {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &.out-of-stock {
                display:none;
                align-items: center;
                background: $outofstock-background;
                flex-direction: column;
                justify-content: center;
            }

            .out-of-stock-label {
                color: $outofstock-color;
                font-weight: 500;
                font-size: rem-calc(16);
            }
        }

        &.out-of-stock {
            .overlay.out-of-stock {
                display: flex;
            }
        }
    }

    .tile-body,
    .product-tile-body {
        .pdp-link,
        .product-name-link {
            padding: rem-calc(15 0 10);
            min-height: rem-calc(90);

            a {
                color: $black;
                text-decoration: none;
                font-weight: 400;

                @include media-breakpoint-down(md) {
                    font-size: rem-calc(16);
                }
            }
        }

        .brand-name-text {
            font-size: rem-calc(14);
            color: $color-title;
            font-weight: 500;
            text-transform: uppercase;
            height: 24px;
            display: block;

            @include media-breakpoint-down(md) {
                min-height: rem-calc(55);
            }
        }

        .price,
        .product-price {
            font-size: rem-calc(16);
            color: $black;
            padding-top: rem-calc(10);

            .starting,
            .range,
            .sales {
                font-weight: normal;
            }
        }
    }

    .size-swatches {
        .variant-switch {
            width: rem-calc(85);
            height: rem-calc(35);
            border-radius: rem-calc(5);
            border: 1px solid $swatch-border;
            background-color: transparent;
            margin: 0 rem-calc(10) rem-calc(5) 0;
            font-size: rem-calc(16);
            font-weight: 500;
            position: relative;

            @include media-breakpoint-down(lg) {
                font-size: rem-calc(12);
                width: rem-calc(56);
            }

            &.selected:not(.unselectable) {
                background-color: $product-variation-active;
                border-color: $swatch-border-active;
            }

            &:hover {
                background-color: $product-variation-hover;
                border-color: $swatch-border-hover;
            }

            &.unselectable {
                background-color: $product-variation-unselectable;
                border-color: $swatch-border-unselectable;
                color: $place-holder;

                &.selected {
                    border-color: $swatch-border-unselectable-active;
                }
            }
        }
    }

    .sales {
        @include media-breakpoint-down(md) {
            font-size: rem-calc(14);
        }

        .value {
            font-weight: 700;

            @include media-breakpoint-down(md) {
                font-weight: 500;
            }
        }
    }
}

.refinements {
    margin: 0;

    .card {
        background-color: transparent;
        border: none;
        box-shadow: none;
        margin-bottom: rem-calc(32);
        padding: 0;

        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }
    }

    .card-header {
        padding: rem-calc(12 0);
        margin: 0;
        font-weight: 700;
        font-size: rem-calc(16);
        color: $black;
        border-top: 1px solid $card-border;
        background-color: transparent;
        border-bottom: none;
        text-transform: capitalize;

        p {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            margin-bottom: 0;
        }
    }

    .card-body {
        padding: 0;
    }

    &.refinement-category {
        li {
            margin: 0;
        }
    }

    .header,
    .values {
        margin: 0;
    }

    ul {
        li {
            padding: 0;

            button {
                padding: 0;
            }
        }
    }

    .header-bar {
        background-color: none;
        color: $black;
    }
}

.sort-order {
    margin-bottom: rem-calc(16);
}

.grid-header,
.content-grid-header {
    margin-top: 0;
}

.product-grid {
    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(-55);
    }
}
