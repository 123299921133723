@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.contentWithImage-content-title {
    color: var(--primary-purina-black);
    font-family: var(--font-primary);
    font-size: var(--text-lg);
    font-weight: bold;
    padding-bottom: 15px;

    @include media-breakpoint-up(lg) {
        font-size: var(--text-xl);
    }
}

.contentWithImage-content-text {
    p {
        color: var(--primary-purina-black);
        font-family: var(--font-primary);
        font-size: var(--text-sm);
        font-weight: 300;

        @include media-breakpoint-up(lg) {
            font-size: var(--text-sm);
        }
    }

    a {
        color: var(--primary-purina-black);
        text-decoration: underline;
    }
}

.contentWithImage-buttons {
    .btn {
        background-color: var(--primary-purina-dark-grey);
        color: #fff;
        font-family: var(--font-primary);
        font-size: var(--text-sm);
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            font-size: var(--text-sm);
        }

        /* stylelint-disable-next-line */
        &:hover {
            background-color: $white;
            color: var(--primary-purina-dark-grey);
        }

        /* stylelint-disable-next-line */
        &:focus {
            color: $white;
        }
    }
}
