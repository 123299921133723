@import "~base/components/common";
@import "bootstrap/scss/mixins/breakpoints";

body {
    color: $black;
    background-color: $body-bg;
    font-family: $font-antenna-purina;
    font-weight: 300;
}

html {
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: unset;
}

.page {
    background-color: $grey1;

    > .startup-kit-page {
        background-color: $white;
    }
}

.form-control {
    border-color: $black;
    height: rem-calc(48);
    font-size: rem-calc(14);
    color: $place-holder;

    &.is-invalid {
        color: $error-color;
    }

    &:disabled,
    [readonly] {
        font-size: rem-calc(14);
        color: $grey4;
        background-color: $grey1;
        border-color: $grey4;
    }

    &:focus,
    &:active,
    &:hover {
        border-color: $input-active;
        box-shadow: none;
    }
}

.no-scroll {
    overflow: hidden;
}

.invalid-feedback {
    color: $error-color;
}

input[placeholder] {
    color: $place-holder;
}

label,
.form-control-label {
    font-size: rem-calc(14);
    color: $black;
}

.line-item-pricing-info {
    margin-bottom: rem-calc(8);
}

.card-header {
    margin: rem-calc(0 16);
    padding-left: 0;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(24 0);
        margin: rem-calc(0 32);
        font-weight: 700;
        font-size: rem-calc(30);
    }
}

.card-body {
    padding: rem-calc(20 16);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(20 32);
    }
}

.card-header-custom {
    font-size: rem-calc(20);
    font-weight: 700;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(24);
    }
}

.card {
    border-color: $card-border;

    &.ghost {
        border: none;
        background-color: $white;
        opacity: unset;

        .card-header {
            border: none;
        }
    }
}

.checkout-btn,
.c-checkout__btn-checkout,
.order-confirmation-continue-shopping,
.c-button--primary {
    background-color: $primary;
    border: none;
    color: $black;
    font-size: rem-calc(14);
    padding: rem-calc(12 16);
    text-transform: uppercase;
    letter-spacing: rem-calc(1);

    &:disabled,
    [readonly] {
        background-color: $button-disable;
        color: $black;
        opacity: 1;
    }

    &:hover {
        color: $black;
        background-color: $product-variation-hover;
    }

    &.btn-color-filled {
        color: $black;
        margin-right: rem-calc(20);
    }
}

@mixin input-field() {
    height: rem-calc(48);
    border-radius: rem-calc(4);
    border: rem-calc(1) solid $black;
    padding: rem-calc(14 16);
    width: auto;
    font-size: rem-calc(14);
}


.fluid-grid {
    margin: 0 auto;
    max-width: calc(100vw - 32px);

    @include media-breakpoint-up(md) {
        max-width: calc(100vw - 48px);
    }

    @include media-breakpoint-up(lg) {
        max-width: 1440px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1440px;
    }
}

.fluid-grid-no-mg {
    @include media-breakpoint-down(md) {
        max-width: 100vw;
    }
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: $black;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: $primary;

    &::after {
        color: $black;
    }
}

.c-account__edit-link,
.profile-back-to-account-link  {
    text-decoration: underline;
    font-size: rem-calc(14);
    color: #{var(--skin-primary-color-1)} !important;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-antenna-purina;
}

.common-margin {
    padding: rem-calc(24 16);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(32);
    }
}


#auto-complete-results {
    list-style: none;
    margin-top: rem-calc(-16);
    padding-left: rem-calc(10);

    li.pac-item {
        padding: rem-calc(5 0);
        cursor: pointer;

        &:first-child {
            margin-top: rem-calc(20);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.breadcrumb {
    padding: rem-calc(16 0 16);
    font-size: rem-calc(16);
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        font-size: rem-calc(14);
    }

    .breadcrumb-item {
        + .breadcrumb-item {
            padding: 0;

            &::before {
                display: none;
            }
        }

        .breadcrumb-icon {
            margin: rem-calc(0 8);
            position: relative;
            top: rem-calc(-2);
        }

        a {
            color: $black;

            @include media-breakpoint-down(lg) {
                font-size: rem-calc(14);
            }
        }
    }
}

.recommendation-slider {
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-up(lg) {
        width: 85%;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, -15px);

        @include media-breakpoint-up(lg) {
            right: var(--swiper-navigation-sides-offset, -7%);
        }
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: var(--swiper-navigation-sides-offset, -15px);

        @include media-breakpoint-up(lg) {
            left: var(--swiper-navigation-sides-offset, -7%);
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: $black;
        background-color: $primary;
        top: var(--swiper-navigation-top-offset, 35%);
    }
}

h1,
.h1 {
    font-size: rem-calc(32);
    font-weight: 700;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(40);
    }
}

.cart-recomm {
    text-align: center;
    margin: 0;
    padding: rem-calc(40 0 20);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(32);
        padding: rem-calc(40 0 24);
    }

    .cart-recomm-title {
        font-size: rem-calc(32);
        font-weight: 700;
    }
}

.reset-password-screen {
    width: 100%;
}

.reset-password-screen_content {
    margin: rem-calc(20 0);
}

.modal {
    .modal-header,
    &-title {
        font-size: 20px;
        font-weight: 700;
    }
}
// START: style for Checkbox
input[type="checkbox"] {
    appearance: none;
    margin: rem-calc(0 5 0 0);
    position: relative;
    border-width: 0 !important;

    &::before {
        width: rem-calc(20);
        height: rem-calc(20);
        content: "";
        background-color: $color-white;
        border: 1px solid  $color-black;
        display: inline-block;
        position: relative;
        top: rem-calc(3);
        border-radius: rem-calc(4);
    }

    &:checked::after {
        width: rem-calc(12);
        height: rem-calc(12);
        content: "";
        position: absolute;
        background-image: url("../images/checked.svg");
        background-repeat: no-repeat;
        top: rem-calc(8);
        left: rem-calc(4);
    }

    &:hover,
    &:focus,
    &:checked {
        border-width: 0 !important;
        box-shadow: none !important;

        &::before {
            border-color: $button-hover;
        }
    }
}
// END: style for Checkbox

// START: style for Radio Box
input[type="radio"] {
    appearance: none;
    margin: rem-calc(0 5 0 0);
    position: relative;
    border-width: 0 !important;

    &::before {
        width: rem-calc(20);
        height: rem-calc(20);
        content: "";
        background-color: $color-white;
        border: 1px solid  $color-black;
        display: inline-block;
        position: relative;
        top: rem-calc(3);
        border-radius: 50%;
    }

    &:checked::after {
        width: rem-calc(12);
        height: rem-calc(12);
        background-color: $button-hover;
        border-radius: 50%;
        content: "";
        position: absolute;
        top: rem-calc(7);
        left: rem-calc(4);
    }

    &:hover,
    &:focus,
    &:checked {
        border-width: 0 !important;
        box-shadow: none !important;

        &::before {
            border-color: $button-hover;
        }
    }
}
// END: style for Radio Box

// START: style for page pagination
.pagination-wrapper {
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(40);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(80);
    }

    .pagination {
        justify-content: center;
    }

    .pages {
        font-size: 0;
        display: flex;
    }

    .js-breed-page,
    .page-no,
    .prev,
    .next  {
        padding: rem-calc(12 19);
        font-size: rem-calc(12);
        font-weight: 400;
        color: var(--primary-purina-black);
        border: 1px solid $bg-color;
        background-color: transparent;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
        }
    }

    .prev,
    .next {
        font-size: rem-calc(20);
        padding: rem-calc(0 20);

        .fa {
            font-weight: 700;
        }
    }

    .active {
        background-color: $text-color;
        color: $white;
        border-color: $text-color;
    }
}
// END: style for page pagination// END: style for page pagination

.product-details-slider-wrapper {
    .thumb-items {
        width: rem-calc(90) !important;
        height: rem-calc(90);
        margin: rem-calc(8);
        background-color: $white;
        cursor: pointer;
        text-align: center;

        img {
            max-width: 100%;
            max-height: rem-calc(90);
        }

        &:first-child {
            margin: rem-calc(8 8 8 0);
        }
    }
}


.card-footer-border,
.card-footer-border {
    border-top: none !important;
}

.leading-lines {
    .start-lines::before {
        display: none;
    }
}

.css-16javxt {
    right: 80px !important;
    bottom: 14px !important;
}

.experience-einstein-einsteinCarousel,
.experience-einstein-einsteinCarouselCategory,
.experience-einstein-einsteinCarouselProduct,
.experience-commerce_layouts-carousel {
    .carousel-title {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        color: #1E1E1E;
        font-family: var(--font-primary);
        font-size: var(--text-xl);
        font-weight: 700;
        line-height: 32px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
            font-size: var(--text-2xl);
            line-height: 40px;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        align-items: center;
        border-radius: 4px;
        /* stylelint-disable-next-line */
        box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%), 0px 2px 4px -2px rgb(0 0 0 / 10%);
        // color: var(--swiper-navigation-color, var(--swiper-theme-color));
        color: #555;
        cursor: pointer;
        display: flex;
        height: var(--swiper-navigation-size);
        justify-content: center;
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        top: var(--swiper-navigation-top-offset, 50%);
        // width: calc(var(--swiper-navigation-size) / 44 * 27);
        width: var(--swiper-navigation-size);
        z-index: 10;
        background-color: #d2c08a;
        opacity: 1;

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            background-image: none;
            content: url("../images/chevron-right.svg");
            height: 20px;
        }

        .carousel-control-prev-icon {
            content: url("../images/chevron-right.svg");
            transform: rotate(180deg);
            left: 20%;
        }

        .carousel-control-next-icon {
            right: 20%;
        }
    }
}