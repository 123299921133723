@import "../../../../../../../../app_purina_core/cartridge/client/default/scss/experience/components/assets/contentWithImage";

#homepage-Purina_AU {
    .experience-assets-contentWithImage {
        .contentWithImage-buttons {
            .btn {
                transition: none;

                &:hover {
                    transform: none;
                }
            }
        }
    }
}

.experience-assets-contentWithImage {
    .contentWithImage-buttons {
        .btn {
            transition: none !important;

            &:hover {
                transform: none !important;
            }
        }
    }
}
