// Purpose: Styles for the header search
// Dependencies: _variables.scss, _mixins.scss
// Header Search
// =============

.site-search {
    height: auto;

    @include media-breakpoint-down(md) {
        width: auto;
        margin: 0;
    }

    input {
        height: rem-calc(48);
        border: none;
        margin-right: 0;

        @include media-breakpoint-down(md) {
            padding-left: rem-calc(20);
        }

        @include media-breakpoint-up(lg) {
            padding-right: rem-calc(60);
        }

        
    }

    input[placeholder] {
        color: $color-black;
        font-size: rem-calc(16);
    }

    .fa-search {
        top: rem-calc(14);

        &::before {
            background-image: url("../images/search.svg");
            content: "";
            width: rem-calc(20);
            height: rem-calc(20);
            display: inherit;
        }
    }

    .fa-close {
        top: rem-calc(15);

        @include media-breakpoint-up(lg) {
            right: rem-calc(40);
        }
    }

    form {
        margin: 0;
    }
}

.suggestions-wrapper {
    top: rem-calc(-2);

    .suggestions {
        @include media-breakpoint-down(md) {
            display: flex;
            position: fixed;
            width: 100%;
            z-index: 110;
        }
    }   
}

.search-mobile {
    .reset-button {
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }
}

.suggestions,
.suggestions.modal {
    width: 100%;
    max-width: rem-calc(320);
    border: 1px solid $swatch-border-active;
    border-radius: 0;
    box-shadow: 0 rem-calc(2) rem-calc(5) 0 rgba(0, 0, 0, 0.25);
    padding: rem-calc(0 20);

    @include media-breakpoint-down(md) {
        max-width: calc(100% - 32px);
        left: rem-calc(16);
        margin-top: rem-calc(-2);
    }

    .header {
        font-size: rem-calc(12);
        color: $grey4;

        @include media-breakpoint-down(md) {
            padding-top: rem-calc(16);
        }

        &:not(:first-child) {
            border-top: 1px solid $swatch-border-active;
        }

        * {
            padding-left: rem-calc(5);

            @include media-breakpoint-down(md) {
                padding-left: rem-calc(0);
            }
        }
    }

    .swatch-circle {
        margin-right: rem-calc(10);
    }

    .list,
    .items {
        .item {
            padding-left: 0;
            padding-right: 0;
            font-weight: 400;
        }

        .name {
            font-size: rem-calc(14);
            color: $color-black;
        }
    }
}

.search-field {
    color: $color-black;
    font-size: rem-calc(16);
    border-radius: rem-calc(4);

    &::placeholder {
        color: $color-black;
        font-size: rem-calc(16);
    }
}

.c-search {
    padding-top: rem-calc(20);

    @include media-breakpoint-down(md) {
        width: 100%;
        padding: rem-calc(0 15 0);
    }

    .reset-button {
        top: rem-calc(15);
        right: rem-calc(40);
    }
}
