@import "~base/components/footer";

.c-footer {
    background-color: $black;
    color: $white;


    &__hr {
        border-color: $hr-border-color;
        margin: rem-calc(10 0 25 0);

        @include media-breakpoint-down(md) {
            margin: rem-calc(32 0);
        }
    }

    &__title {
        color: $primary;
        text-transform: uppercase;
        font-size: rem-calc(16);
    }

    &__links {
        color: $white;
        font-size: rem-calc(14);

        &:hover {
            color: $primary;
        }
    }

    &__copyright-notice {
        font-size: rem-calc(12);
        margin-bottom: 0;

        .copyright-text {
            margin-bottom: rem-calc(10);
        }
    }

    &__checkout-bottom {
        margin: 0;
        font-size: rem-calc(14);
        color: $primary;
    }

    .footer-container {
        .footer-item.collapsible-xs button {
            font-family: $font-antenna-purina;
        }
    }

    .content {
        margin-top: rem-calc(10);
    }
}