@import "~base/components/hero";

.hero {
    height: auto;

    h1.page-title {
        top: 0;
        margin: 0;
    }
}

.results-count {
    font-size: rem-calc(16);
    padding: rem-calc(0 0 16);
    color: $black;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(0 0 32);
        font-size: rem-calc(20);
    }
}

h1.page-title {
    color: $black;
    background-color: transparent;
    margin: 0;
    left: 0;
    font-size: rem-calc(32);
    padding: rem-calc(0 0 10);
    color: $black;
    font-weight: 700;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(0 0 20);
        font-size: rem-calc(40);
    }

    &::before {
        display: none;

        @include media-breakpoint-only(xl) {
            width: calc((100vw - #{map-get($container-max-widths, xl)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, xl)}) / 2 * -1);
        }

        @include media-breakpoint-only(lg) {
            width: calc((100vw - #{map-get($container-max-widths, lg)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, lg)}) / 2 * -1);
        }

        @include media-breakpoint-only(md) {
            width: calc((100vw - #{map-get($container-max-widths, md)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, md)}) / 2 * -1);
        }
    }

    @include media-breakpoint-only(xl) {
        left: 0;
    }

    @include media-breakpoint-only(lg) {
        left: 0;
    }

    @include media-breakpoint-only(md) {
        left: 0;
    }

    @include media-breakpoint-down(sm) {
        left: 0;
    }
}